<template>
  <div>
    <the-breadcrumbs></the-breadcrumbs>
    <page-content page-title="Playlists">
      <template #actions>
        <region-chooser
          v-if="$store.getters['auth/isSuperAdmin']"
          @region:chosen="loadCategories"
        ></region-chooser>
        <el-button type="primary" icon="fal fa-plus" @click="newCategory">
          Neue Playlist
        </el-button>
      </template>
      <el-tabs type="border-card">
        <el-tab-pane label="Playlists anzeigen">
          <el-table
            v-loading="loading"
            :data="nestedCategories"
            :row-class-name="tableRowClassName"
          >
            <el-table-column
              prop="name"
              label="Name"
              sortable
            ></el-table-column>
            <el-table-column prop="thumbnail_url" label="Thumbnail" width="180">
              <template #default="scope">
                <img
                  v-if="scope.row.thumbnail_url"
                  :src="scope.row.thumbnail_url"
                  width="150"
                />
              </template>
            </el-table-column>
            <el-table-column label="Inhalt" width="180">
              <template #default="scope">
                <el-button
                  v-if="scope.row.videos.length > 0"
                  icon="fal fa-play-circle"
                  size="mini"
                  @click="showVideoCategory(scope.row.id)"
                >
                  {{ scope.row.videos.length }} Videos
                </el-button>
                <el-button
                  v-else-if="
                    scope.row.videos.length === 0 &&
                      scope.row.children.length === 0
                  "
                  size="mini"
                  type="primary"
                  icon="fal fa-plus"
                  @click="newVideo(scope.row.id)"
                >
                  Neues Video
                </el-button>
              </template>
            </el-table-column>
            <el-table-column label="Aktionen" width="260">
              <template #default="scope">
                <el-button
                  icon="fal fa-edit"
                  size="mini"
                  @click="handleEdit(scope.row)"
                >
                  Bearbeiten
                </el-button>
                <delete-button
                  v-slot="slotProps"
                  :on-delete="deleteCategory"
                  subject="Playlist"
                >
                  <el-button
                    size="mini"
                    icon="fal fa-trash-alt"
                    type="danger"
                    @click="slotProps.handleDelete(scope.row)"
                  >
                    Löschen
                  </el-button>
                </delete-button>
              </template>
            </el-table-column>
          </el-table>
        </el-tab-pane>
        <el-tab-pane label="Playlists sortieren">
          <el-alert
            title="Hinweis"
            description="Die Playlists können per Drag & Drop verschoben und sortiert werden."
            type="info"
            show-icon
            style="margin-bottom: 20px;"
          >
          </el-alert>
          <el-tree
            :props="treeProps"
            :data="categories"
            node-key="id"
            draggable
            :allow-drop="allowDrop"
            @node-drop="handleDrop"
          >
          </el-tree>
        </el-tab-pane>
      </el-tabs>
    </page-content>
  </div>
</template>

<script>
import TheBreadcrumbs from "@/components/TheBreadcrumbs"
import PageContent from "@/components/PageContent"
import RegionChooser from "@/components/RegionChooser"
import DeleteButton from "@/components/DeleteButton.js"
import VideoCategoriesRepository from "@/repositories/video_categories_repository.js"
import ApiErrorHandlerMixin from "@/mixins/api_error_handler.js"

export default {
  metaInfo: {
    title: "Playlists"
  },
  components: {
    TheBreadcrumbs,
    PageContent,
    RegionChooser,
    DeleteButton
  },
  mixins: [ApiErrorHandlerMixin],
  data() {
    return {
      loading: false,
      categories: [],
      treeProps: {
        label: "name"
      }
    }
  },
  computed: {
    nestedCategories() {
      let arr = []
      let that = this
      this.categories.forEach(function(cat) {
        arr = arr.concat(that.getCategoryChildren(cat))
      })
      return arr
    }
  },
  async created() {
    this.loadCategories()
  },
  methods: {
    async loadCategories() {
      this.loading = true

      try {
        this.categories = await VideoCategoriesRepository.getAll()
        this.loading = false
      } catch (error) {
        this.loading = false
        this.handleApiError(error)
      }
    },
    getCategoryChildren(category, depth = 0) {
      let children = []
      let that = this
      children.push({
        ...category,
        name: `${"–".repeat(depth)} ${category.name}`,
        depth: depth
      })
      category.children.forEach(function(cat) {
        children = children.concat(that.getCategoryChildren(cat, depth + 1))
      })
      return children
    },
    handleEdit(category) {
      this.$router.push({
        name: "EditVideoCategoryPage",
        params: { id: category.id }
      })
    },
    async deleteCategory(category) {
      this.categories.splice(this.categories.indexOf(category), 1)
      await VideoCategoriesRepository.destroy(category.id)
    },
    newCategory() {
      this.$router.push({ name: "NewVideoCategoryPage" })
    },
    newVideo(categoryId) {
      this.$router.push({
        name: "NewVideoPage",
        params: { video_category_id: categoryId }
      })
    },
    async handleDrop(draggingNode, dropNode, dropType) {
      if (dropType === "inner") {
        // Direkt in einen Node verschieben
        draggingNode.data.parent_id = dropNode.data.id
        VideoCategoriesRepository.update(draggingNode.data.id, {
          parent_id: dropNode.data.id
        })
      } else if (dropType === "before" || dropType === "after") {
        if (draggingNode.data.parent_id === dropNode.data.parent_id) {
          // Innerhalb desselben Parents umsortieren
          const category_ids = dropNode.parent.childNodes.map(
            node => node.data.id
          )
          VideoCategoriesRepository.sort(category_ids)
        } else {
          if (dropType === "after") {
            // Nach einen Node eines anderen Parents setzen
            draggingNode.data.parent_id = dropNode.parent.data.id
            await VideoCategoriesRepository.update(draggingNode.data.id, {
              parent_id: dropNode.parent.data.id
            })
            const category_ids = dropNode.parent.childNodes.map(
              node => node.data.id
            )
            VideoCategoriesRepository.sort(category_ids)
          } else {
            if (dropNode.level === 1) {
              // Node ins Top-Level vor einen anderen Node schieben
              draggingNode.data.parent_id = null
              await VideoCategoriesRepository.update(draggingNode.data.id, {
                parent_id: null
              })
              const category_ids = this.categories.map(cat => cat.id)
              VideoCategoriesRepository.sort(category_ids)
            } else {
              // Vor einen Node eines anderen Parents setzen
              draggingNode.data.parent_id = dropNode.parent.data.id
              await VideoCategoriesRepository.update(draggingNode.data.id, {
                parent_id: dropNode.parent.data.id
              })
              const category_ids = dropNode.parent.childNodes.map(
                node => node.data.id
              )
              VideoCategoriesRepository.sort(category_ids)
            }
          }
        }
      }
    },
    allowDrop(draggingNode, dropNode, type) {
      if (type === "inner") {
        return dropNode.data.videos.length === 0
      } else {
        return true
      }
    },
    showVideoCategory(categoryID) {
      this.$router.push({
        name: "ShowVideoCategoryPage",
        params: { id: categoryID }
      })
    },
    tableRowClassName({ row }) {
      if (row.depth === 0) {
        return ""
      } else {
        return `row-level-${row.depth}`
      }
    }
  }
}
</script>

<style lang="scss">
$color: white;

.el-table .row-level-1 {
  background: darken($color, 2%);
}

.el-table .row-level-2 {
  background: darken($color, 4%);
}

.el-table .row-level-3 {
  background: darken($color, 6%);
}

.el-table .row-level-4 {
  background: darken($color, 8%);
}

.el-table .row-level-5 {
  background: darken($color, 10%);
}
</style>
